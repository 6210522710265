import axios from '@/plugins/axios';

class IntegrationService {
  getIntegrations(data) {
    this.data = data;
    return axios().get('integrations', { params: this.data }).then((response) => response.data);
  }

  getIntegration(id) {
    this.id = id;
    return axios().get(`integrations/${this.id}`);
  }

  getInstalledIntegrations(data) {
    this.data = data;
    return axios().get('integrations/installed', { params: this.data }).then((response) => response.data);
  }

  installIntegration(id) {
    this.id = id;
    return axios().put(`integrations/${this.id}/install`).then((response) => response.data);
  }

  uninstallIntegration(id) {
    this.id = id;
    return axios().put(`integrations/${this.id}/uninstall`).then((response) => response.data);
  }

  configureIntegration(id, data) {
    this.data = data;
    return axios().put(`integrations/${id}/configure`, this.data).then((response) => response.data);
  }

  createInvoice(data) {
    this.data = data;
    return axios().post('invoices', this.data).then((response) => response.data);
  }

  deleteInvoice(id) {
    this.a = id;
    return axios().delete(`invoices/${id}`, this.data).then((response) => response.data);
  }
}

export default new IntegrationService();
