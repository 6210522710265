<template>
  <div
    :id="modalId"
    :class="classes"
    :style="this.styles"
    tabindex="-1"
    role="dialog"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Nova Fatura</h5>
          <button type="button" class="close" @click="hide" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <form @submit.prevent="save" class="form-horizontal">
          <div class="modal-body">
            <div class="form-body">
              <div class="form-group">
                <label for="balance">Quantidade a adicionar ao seu saldo</label>
                <input
                  type="tel"
                  class="form-control"
                  id="balance"
                  aria-describedby="balanceHelp"
                  required
                  v-model="form.value"
                  v-money="money"
                  :class="{ invalid: invalidValue }"
                />
                <small id="balanceHelp" class="form-text text-muted">
                  O valor mínimo de R$ 100,00 e valor máximo de R$ 5.000,00.
                </small>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="hide">
              {{$t('generic-str.cancel')}}
            </button>
            <button
              type="submit"
              class="btn btn-primary"
              :class="{ 'qt-loader qt-loader-mini qt-loader-right': isSending }"
              :disabled="isSending"
            >
              Gerar
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import BillingService from '@/services/invoices.service';
import Modal from '@/mixins/Modal';
import { mask } from 'vue-the-mask';

export default {
  mixins: [Modal],
  directives: {
    mask,
  },
  props: ['id'],
  data() {
    return {
      isSending: false,
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2,
        masked: false,
      },
      form: {
        value: 10000,
      },
      invalidValue: false,
    };
  },
  methods: {
    save() {
      const rawValue = this.form.value.replace(/[^0-9]/g, '') / 100;
      if (rawValue < 100) {
        this.invalidValue = true;
        return;
      }
      if (rawValue > 5000) {
        this.invalidValue = true;
        return;
      }
      this.isSending = true;

      if (!this.invalidValue) {
        BillingService.createInvoice(this.form)
          .then(
            (response) => {
              console.log(response);
              this.$toast.show({
                title: this.$t('generic-str.success'),
                content: 'Fatura gerada',
                type: 'success',
              });
              this.$emit('sent');
              this.hide();
            },
            (error) => {
              this.$toast.show({
                title: `${this.$t('sms.infos.filters.status.lbl-error')} ${error.code}`,
                content: error.message,
                type: 'danger',
              });
            },
          )
          .finally(() => {
            this.isSending = false;
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.vti__dropdown-list {
  z-index: 1000 !important;
}
.vue-tel-input {
  border-color: #dfe7f3 !important;
  border: 1px solid rgba(120, 141, 180, 0.3) !important;
}
.loading-cep {
  right: 40px;
  top: 40px;
  position: absolute;
  padding: initial;
}
</style>
