<template>
  <div class="content">
    <PageHeader :title="$tc('generic-str.menu.installed', 1)" />
    <div class="page-content container-fluid">
      <div class="card">
        <div class="card-body block-el p-0">
          <div class="responsive-table">
            <table v-if="fetched" class="table">
              <thead class="bg-light">
                <tr>
                  <th scope="col">{{$tc('generic-str.name', 1)}}</th>
                  <th scope="col">ID</th>
                  <th scope="col">{{$t('generic-str.active-product')}}</th>
                  <th scope="col">{{$t('generic-str.provider')}}</th>
                </tr>
              </thead>
              <tbody>
                <router-link
                  v-for="integration in integrations"
                  :key="integration.id"
                  :to="`/integrations/${integration.id}`"
                  tag="tr"
                  role="button"
                >
                  <td data-label="Data">
                    <div>{{ integration.name }}</div>
                  </td>
                  <td data-label="ID">
                    <div>{{ integration.installed.id }}</div>
                  </td>
                  <td data-label="Produtos Ativos">
                    <div>
                      <i class="icon dripicons-message"></i>
                    </div>
                  </td>
                  <td data-label="Tipo">
                    <div>{{ integration.provider_name }}</div>
                  </td>
                </router-link>
              </tbody>
            </table>
            <!-- <div v-else class="relative qt-block-ui" style="padding: 120px" /> -->
            <div v-else class="static qt-block-ui" style="padding: 120px" />
            <pagination :lastPage="pages" @change="fetch" />
          </div>
        </div>
      </div>
    </div>
    <new-invoice-modal
      @sent="fetch(form.page)"
      id="new-invoice-modal"
    ></new-invoice-modal>
  </div>
</template>

<script>
// @ is an alias to /src
import IntegrationService from '@/services/integration.service';
import PageHeader from '@/components/PageHeader.vue';
import NewInvoiceModal from '@/components/invoices/NewInvoiceModal.vue';
import Pagination from '@/components/Pagination.vue';

export default {
  name: 'IntegrationCatalog',
  components: {
    PageHeader,
    NewInvoiceModal,
    Pagination,
  },
  data() {
    return {
      fetched: false,
      integrations: [],
      form: {
        page: 1,
      },
      pages: 1,
    };
  },
  created() {
    this.fetch(1);
  },
  methods: {
    fetch(page) {
      this.fetched = false;
      this.form.page = page;
      IntegrationService.getInstalledIntegrations(this.form).then(
        (integrations) => {
          this.fetched = true;
          this.integrations = integrations;
        },
        (error) => {
          this.content = error;
        },
      );
    },
  },
};
</script>

<style scoped lang="scss">
.btn-search-id {
  height: 35px;
}
.btn-export {
  margin-left: 1px;
  padding: 8px 20px !important;
}
.m-top-10 {
  margin-top: 10px;
}
</style>
